import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>Bookkeeping Services Toronto | Certus Bookkeeping</title>
      <meta name="description" content="Bookkeeping Services in Toronto providing for businesses of all sizes. Get in touch with us today to get started. " />
      <link rel="canonical" href="https://certusbookkeeping.com/bookkeeping-services-toronto" />
    </Helmet>
  );
}
